<template>
  <span
    class="text-logo"
    :class="{
      'text-logo--dark': dark,
      'text-logo--inverted': inverted,
      'text-logo--clickable': attrs.onClick,
    }"
  >
    <span class="text-logo__moment">moment</span><span class="text-logo__share">share</span><span v-if="showExtension">.io</span>
  </span>
</template>

<script setup>
  defineProps({
    dark: {
      type: Boolean,
      default: false,
    },
    inverted: {
      type: Boolean,
      default: false,
    },
    showExtension: {
      type: Boolean,
      default: false,
    },
  })

  const attrs = useAttrs()
</script>

<style lang="scss">
  .text-logo {
    font-size: 32px;
    color: white;
    font-weight: 200;
  }

  .text-logo--dark .text-logo__moment {
    color: black;
  }

  .text-logo--inverted .text-logo__share {
    color: black;
  }

  .text-logo--dark.text-logo--inverted .text-logo__moment {
    color: black;
  }

  .text-logo--dark.text-logo--inverted .text-logo__share {
    color: white;
  }

  .text-logo--clickable {
    cursor: pointer;
  }

  .text-logo__share {
    font-weight: 500;
    color: var(--color-secondary);
  }
</style>
